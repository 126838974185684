




















































































































import Vue from 'vue';
import { getUserList, usersNumber } from '@/api/user';
import moment from 'moment';
export default Vue.extend({
  name: 'userManageList',
  data() {
    return {
      tableLoading: false,
      searchForm: {
        enterpriseName: '',
        queryName: '',
        userCode: '',
        mobile: '',
        creationTime: [],
        accountType: '',
        registerSource: '',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      userManageColumns: [
        {
          title: '姓名',
          dataIndex: 'realName',
          width: '120px',
          scopedSlots: { customRender: 'realName' },
        },
        {
          title: '登录账号',
          dataIndex: 'userCode',
          width: '120px',
          scopedSlots: { customRender: 'userCode' },
        },
        {
          title: '手机号码',
          width: '120px',
          dataIndex: 'mobile',
        },
        {
          title: '账号类型',
          width: '120px',
          dataIndex: 'extend3',
        },
        {
          title: '所属租户',
          width: '200px',
          dataIndex: 'agencyName',
        },
        {
          title: '所属角色',
          dataIndex: 'roleName',
          scopedSlots: { customRender: 'roleName' },
        },
        {
          title: '用户来源',
          dataIndex: 'registerSource',
          width: '200px',
        },
        {
          title: '创建人',
          width: '120px',
          dataIndex: 'recUserName',
        },
        {
          title: '注册时间',
          width: '120px',
          dataIndex: 'registerDate',
        },
        {
          title: '状态',
          dataIndex: 'recStatus',
          width: '100px',
          scopedSlots: { customRender: 'recStatus' },
        },
      ],
      userManageData: [],
      ZBT_DEFAULT: 0,
      ZBT: 0,
      ZBT_WEB: 0,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    dateFormat(date) {
      if (!date) {
        return null;
      }
      return moment(+date).format('YYYY-MM-DD');
    },
    toUnix(date) {
      if (!date) {
        return '';
      }
      return new Date(date).getTime();
    },
    getStartTime(time) {
      if (time) {
        return moment(time).startOf('day').valueOf();
      } else {
        return '';
      }
    },
    getEndTime(time) {
      if (time) {
        return moment(time).endOf('day').valueOf();
      } else {
        return '';
      }
    },
    fetchData() {
      this.usersNumber();
      this.tableLoading = true;
      const { creationTime, ...query } = this.searchForm;
      const [startTime = '', endTime = ''] = creationTime;
      getUserList({
        ...query,
        startTime: this.getStartTime(startTime),
        endTime: this.getEndTime(endTime),
        page: this.pagination.current,
        limit: this.pagination.pageSize,
      }).then((res) => {
        const {
          result: { list, total },
        } = res;
        this.userManageData = list?.map((item) => ({
          ...item,
          key: item.sequenceNbr,
          realName: item.realName || item.username,
          extend3:
            item.masterAccount === null
              ? ''
              : item.masterAccount === '1'
              ? '主账户'
              : '子账户',
          registerDate: this.dateFormat(item.registerDate),
        }));
        this.pagination.total = total;
        this.tableLoading = false;
      });
    },
    handleSubmit() {
      this.pagination.current = 1;
      this.fetchData();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.fetchData();
    },
    onNameClick({ openId, agencyCode }) {
      this.$router.push({
        path: '/platform/userManage/details',
        query: { id: openId, agencyCode },
      });
    },
    usersNumber() {
      usersNumber().then((res) => {
        if (res) {
          const data = res.result;
          (this.ZBT_DEFAULT = data.ZBT_DEFAULT),
            (this.ZBT = data.ZBT),
            (this.ZBT_WEB = data.ZBT_WEB);
        }
      });
    },
  },
});
